import {FC} from 'react'
import {Link} from 'react-router-dom'

const PrivacyPolicy: FC = () => {
  return (
    <>
      {/* begin::Title */}
      <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Política de Privacidade</h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className='fw-semibold fs-6 text-gray-500 mb-7'>
Esta Política de Privacidade descreve como o aplicativo Chave CMB (desenvolvido pela empresa CMB Soluções em Tecnologia Ltda.) coleta, utiliza e protege as informações pessoais que você fornece ao utilizar nosso aplicativo e os serviços relacionados a ele.

Informações que Coletamos

Ao utilizar nosso aplicativo, podemos coletar informações que podem ser usadas para identificá-lo ("Informações Pessoais"). As informações pessoais podem incluir, mas não estão limitadas a:
- Nome e sobrenome;
- Endereço de e-mail;
- Número de telefone;
- Endereço IP (Internet Protocol);
- Informações do navegador e dispositivo;
- Preferências de comunicação;
- Informações relacionadas à conexão Bluetooth e dispositivos vinculados.

Como Usamos Suas Informações

As informações pessoais coletadas são utilizadas para fornecer e melhorar os serviços do aplicativo Chave CMB. Podemos usar suas informações pessoais para:

- Personalizar sua experiência e responder às suas necessidades individuais;
- Fornecer suporte ao cliente;
- Comunicar-nos com você sobre o seu uso do aplicativo;
- Enviar-lhe atualizações sobre nossos serviços e outras informações que acreditamos ser do seu interesse;
- Melhorar nosso aplicativo e serviços;
- Proteger a segurança e a integridade do nosso aplicativo.

Como Protegemos Suas Informações

Valorizamos a segurança das suas informações pessoais e implementamos medidas de segurança para protegê-las contra acesso não autorizado, alteração, divulgação ou destruição. No entanto, observe que nenhuma transmissão pela Internet ou método de armazenamento eletrônico é 100% seguro e confiável, e não podemos garantir sua segurança absoluta.

Compartilhamento de Informações

Não vendemos, trocamos ou transferimos suas informações pessoais para terceiros sem o seu consentimento explícito, exceto conforme descrito nesta Política de Privacidade ou quando exigido por lei.

Cookies e Tecnologias Semelhantes

Nosso aplicativo pode utilizar cookies ou tecnologias semelhantes para melhorar sua experiência de usuário. Você pode configurar seu dispositivo para recusar todos os cookies ou para indicar quando um cookie está sendo enviado. Se você optar por não aceitar cookies, algumas funcionalidades do aplicativo podem ser comprometidas.

Consentimento

Ao utilizar o aplicativo Chave CMB, você consente com esta Política de Privacidade e concorda em fornecer as informações pessoais mencionadas.

Alteracoes nesta Política de Privacidade

Reservamo-nos o direito de atualizar ou modificar esta Política de Privacidade a qualquer momento, conforme necessário. As alterações entrarão em vigor imediatamente após serem publicadas nesta página.

Contato

Se você tiver alguma dúvida sobre esta Política de Privacidade, entre em contato conosco através dos seguintes meios:

E-mail: cmbsolucoeskm@gmail.com
Telefone: +55 11 4563-1794

Última atualização: 11/03/2025

Ao continuar usando nosso aplicativo, você confirma que leu e concorda com esta Política de Privacidade.
      </div>
      {/* end::Text */}

      {/* begin::Link */}
      <div className='mb-0'>
        <Link to='/dashboard' className='btn btn-sm btn-primary'>
          Voltar para a Home
        </Link>
      </div>
      {/* end::Link */}
    </>
  )
}

export {PrivacyPolicy}
